import * as React from "react"
import Layout from "../../components/layout"
import Helmet from "react-helmet";

// markup
const AboutPage = () => {
    return (
        <React.Fragment>
            <Helmet title="About"/>
            <Layout>AboutPage</Layout>
        </React.Fragment>
    )
};

export default AboutPage
